import React from 'react';
import ProductCard from './productCardV3';
import style from "./shopV3.module.css"
const ProductList = ({ products }) => {
  return (
    <div className={style.productList}>
      {products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
};

export default ProductList;
