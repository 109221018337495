import React from 'react';
import style from "./shopV3.module.css"

import { useLocation, Link } from 'react-router-dom';
import { animateScroll } from "react-scroll";
import queryString from 'query-string';
const ProductCard = ({ product }) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

    return (
      <Link to={`/product/${product.id}${queryString.stringify(queryParams) ? '?' + queryString.stringify(queryParams) : ''}`} key={product.id} className={style.shopBoxVThree}  onClick={() => animateScroll.scrollToTop()}>
        {product.stock && <span className={style.productStock}>{product.stock}</span> }
     
      
      <img src={product.image} alt={product.name} className={style.mainImage} />
      <div className={style.priceDis}>
      <span className={`${style.productAge} ${product.age === '3-5' ? style.cubAge : ""}`}>
  {product.age === '3-5' ? (
    <>
      <img src="/image/icon/cubb.svg" alt="Cub Club Icon" /> Cub Club
    </>
  ) : (
    <>
      <img src="/image/icon/zooo.svg" alt="Zoologist Club Icon" /> Zoologist Club
    </>
  )}

</span>

        <h3>{product.name}</h3>
        <div className={style.starContainer}>
        <p>{product.price}</p>
        <div>
          <img src='/image/star.png'/>
          <p>4.8</p>
        </div>
        </div>
      </div>
      
    </Link>
    
  );
};

export default ProductCard;
