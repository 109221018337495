const productsData = [

  {
    id: 'Anteater-EDventure',
    name: 'Anteater EDventure',
    image: '/image/pr2.png',
    price: '$39.95',
    link:"https://checkout.myzoobox.com/checkout/add/119418",
    // stock:"In Stock!",
    photos: [ '/image/Anteater.webp', '/image/Anteater1.webp', '/image/pr2.png'],
    type: 'Rainforest Animals',
    FanFavorite:"Yes",
    age:"5-12",
    category:"One-time Boxes",
    description:"Embark on an Anteater EDventure to explore the unique features of these insect-eating mammals with elongated snouts. This box includes a wild republic plush anteater, a book all about anteaters, an anatomy poster, and a creative art project with all necessary supplies. Kids will also find fun play binoculars, a postcard from an anteater, a collectible sticker, and a themed recipe.",

    // Add other product details as needed
  },
  // Add 6 more products here
  {
    id: 'Bee-EDventure',
    name: 'Bee EDventure',
    image: '/image/pr3.png',
    price: '$39.95',
    link:"https://checkout.myzoobox.com/checkout/add/254994",
    // stock:"In Stock!",
    photos: [  '/image/pr3.png','/image/Bee.webp', '/image/Bee1.webp'],
    type: 'Animals that Fly',
    FanFavorite:"Yes",
    age:"5-12",
    category:"One-time Boxes",
    description:"Buzz into the busy world of bees, crucial pollinators that play a key role in our ecosystems. The Bee EDventure box contains a wild republic stuffed animal, an educational science book, an anatomy poster & worksheet, a buzz-worthy STEM project, and a bee-themed art project. Extras include bee life cycle figures, a postcard from a bee, a collectible sticker, a honey-themed recipe, and QR printables for more exploration.",

    // Add other product details as needed
  },
  {
    id: 'Bear-EDventure',
    name: 'Bear EDventure',
    image: '/image/pr4.png',
    // stock:"In Stock!",
    price: '$39.95',
    link:"https://checkout.myzoobox.com/checkout/add/254996?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    photos: [ '/image/Bear.webp', '/image/Bear2.webp','/image/Bear4.webp'],
    type: 'Predators',
    FanFavorite:"Yes",
    age:"5-12",
    category:"One-time Boxes",
    description:"Roam into the bear's habitat with the Bear EDventure box, and learn about these mighty mammals found in various environments around the globe. Inside, you'll find a huggable wild republic bear stuffed animal, an educational science book, an anatomy poster & worksheet, a bear-inspired STEM project, and an art project with supplies. Also included are a bear fidget pop-it, a postcard from a bear, a collectible sticker, a bear-themed recipe, and QR printables",

    // Add other product details as needed
  },
  // {
  //   id: 'Belunga-EDventure',
  //   name: 'Belunga EDventure',
  //   image: '/image/pr5.png',
  //   price: '$39.95',
  //   photos: [ '/image/Beluga.webp', '/image/Beluga1.webp', '/image/Beluga2.webp'],
  //   type: 'Ocean Animals',
  //   stock:"In Stock!",
  //   age:"5-12",
  //   category:"One-time Boxes",
  //   description:"Dive into the aquatic life of the Beluga Whale with this EDventure box, highlighting these remarkable white whales known for their vocal communication. The box includes a wild republic plush beluga whale, a comprehensive science book, an anatomy poster, and a marine art project. Plus, there's a mini ocean dig, a beluga postcard, a collectible sticker, and a themed recipe.",

  //   // Add other product details as needed
  // },
  {
    id: 'Butterfly-EDventure',
    name: 'Butterfly EDventure',
    image: '/image/pr6.png',
    price: '$39.95',
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/add/247032?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    photos: [ '/image/pr6.png','/image/butterfly.webp', '/image/butterfly1.webp', ],
    type: 'Animals that Fly',
    FanFavorite:"Yes",
    age:"5-12",
    category:"One-time Boxes",
    description:"Flutter into the colorful world of butterflies, mesmerizing insects known for their life cycle and diverse patterns. The Butterfly EDventure box features a wild republic butterfly stuffed hugger, an engaging science book, an anatomy poster, and a vibrant art project. Additionally, enjoy a grow butterfly, a butterfly postcard, a collectible sticker, and a fun themed recipe.",

    // Add other product details as needed
  },
  {
    id: 'Alligator-EDventure',
    name: 'Alligator EDventure',
    image: '/image/pr7.png',
    price: '$39.95',
    // stock:"In Stock!",
    photos: ['/image/pr7.png', '/image/Alligator.webp', '/image/Alligator1.webp', '/image/Alligator1.webp'],
    type: 'Predators',
    link:"https://checkout.myzoobox.com/checkout/add/119419?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    age:"5-12",
    category:"One-time Boxes",
    description:"Plunge into the Alligator EDventure, exploring these ancient reptiles known for their powerful jaws and armored skin. This box comes with a wild republic alligator stuffed animal, an insightful science book, an anatomy poster, and a themed art project. Extras include an alligator fidget squishy, a postcard from an alligator, a collectible sticker, and a chomping themed recipe.    ",

    // Add other product details as needed
  },
  {
    id: 'Skunk-EDventure',
    name: 'Skunk EDventure',
    image: '/image/skunk.png',
    price: '$39.95',
    photos: [ '/image/Skunk.webp', '/image/Skunk1.webp', '/image/Skunk2.webp', '/image/Skunk3.webp', '/image/Skunk4.webp', '/image/Skunk5.webp'],
    type: 'North American Animals',
    link:"https://checkout.myzoobox.com/checkout/buy/53539 ",
    age:"5-12",
    category:"One-time Boxes"

    // Add other product details as needed
  },
  {
    id: 'Sea Horse EDventure',
    name: 'Sea Horse EDventure',
    image: '/image/seh.png',
    price: '$39.95',
    photos: [  '/image/SeaHorse.webp','/image/SeaHorse1.webp', '/image/SeaHorse2.webp', '/image/SeaHorse3.webp', '/image/SeaHorse4.webp'],

    type: 'Ocean Animals',
    link:"https://checkout.myzoobox.com/checkout/buy/294302 ",
    age:"5-12",
    category:"One-time Boxes"

    // Add other product details as needed
  },
  {
    id: 'Elephant-EDventure',
    name: 'Elephant EDventure',
    image: '/image/pr9.png',
    price: '$39.95',
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/add/119411?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    photos: [ '/image/Elephant.webp', '/image/Elephant1.webp', '/image/Elephant2.webp'],
    type: 'African Animals',
    age:"5-12",
    category:"One-time Boxes",
    description:"Join the Elephant EDventure to learn about these majestic creatures, the largest land animals known for their intelligence and strong social bonds. The box contains a cuddly wild republic elephant stuffed animal, an in-depth science book, an anatomy poster, and a fun craft project. Also included are a elephant mini blocks, an elephant postcard, a collectible sticker, and a themed recipe."

 
    // Add other product details as needed
  },
  // {
  //   id: 'Fennec-Fox-EDventure',
  //   name: 'Fennec Fox EDventure',
  //   image: '/image/pr10.png',
  //   price: '$39.95',
  //   link:"https://checkout.myzoobox.com/checkout/add/255000?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
  //   photos: [ '/image/Fennecfox.webp', '/image/Fennecfox1.webp', '/image/Fennecfox2.webp'],
  //   type: 'African Animals',
  //   age:"5-12",
  //   // stock:"In Stock!",
  //   category:"One-time Boxes",
  //   description:"Venture into the desert with the Fennec Fox EDventure box, highlighting these small, adorable creatures with distinctive large ears. Inside, you'll find a wild republic fennec fox plush, a fascinating science book, an anatomy poster & worksheet, a desert-themed STEM project, and an art project with supplies. Also enjoy a geode from Morocco, a fennec fox postcard, a collectible sticker, a desert-inspired recipe, and QR printables."

   
  //   // Add other product details as needed
  // },
   {
    id: 'Flamingo-EDventure',
    name: 'Flamingo EDventure',
    image: '/image/pr11.png',
    price: '$39.95',
    link:"https://checkout.myzoobox.com/checkout/add/119421",
    photos: [ '/image/Flamingo.webp', '/image/Flamingo1.webp', '/image/Flamingo2.webp'],
    type:'Animals that Fly',
    age:"5-12",
    // stock:"In Stock!",
    category:"One-time Boxes",
    description:"Wade into the world of flamingos, iconic birds known for their pink feathers and one-legged stance, with the Flamingo EDventure box. This box includes a wild republic flamingo stuffed toy, an educational science book, an anatomy poster, and a colorful art project. Extras include flamingo plus-plus blocks, a flamingo postcard, a collectible sticker, and a fun themed recipe."

    // Add other product details as needed
  },
  {
    id: 'Hammerhead-Shark-EDventure',
    name: 'Hammerhead Shark EDventure',
    image: '/image/pr12.png',
    price: '$39.95',
    photos: [ '/image/Hammerheadshark.webp', '/image/Hammerheadshark2.webp', '/image/Hammerheadshark3.webp', '/image/Hammerheadshark4.webp', '/image/Hammerheadshark5.webp'],
    type: 'Ocean Animals',
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/add/257095?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    // stock:"In Stock!",
    category:"One-time Boxes",
    description:"Dive deep into the ocean with the Hammerhead Shark EDventure box, exploring these distinctive sharks known for their unique head shape. The box comes with a wild republic hammerhead shark plush, a captivating science book, an anatomy poster & worksheet, a marine STEM project, and an ocean-inspired art project. Also find a fun hammerhead shark fidget squishy, a shark postcard, a collectible sticker, a sea-themed recipe, and QR printables for more learning."

  
    // Add other product details as needed
  },
  // {
  //   id: 'Humpback-Whale-EDventure',
  //   name: 'Humpback Whale EDventure',
  //   image: '/image/pr13.png',
  //   price: '$39.95',
  //   photos: [ '/image/humpbackwhale.webp', '/image/humpbackwhale1.webp', '/image/humpbackwhale2.webp'],
  //   type: 'Ocean Animals',
  //   stock:"In Stock!",
  //   age:"5-12",
  //   category:"One-time Boxes",
  //   description:"Embark on a Humpback Whale EDventure to discover these magnificent marine mammals known for their impressive leaps and songs. Included are a plush humpback whale, an exclusive science book, an anatomy poster, and a themed craft project. Extras include a speak like a whale whistle, a whale postcard, a collectible sticker, and a fun themed recipe."

  //   // Add other product details as needed
  // },
  // {
  //   id: 'Lemur-EDventure',
  //   name: 'Lemur EDventure',
  //   image: '/image/pr14.png',
  //   price: '$39.95',
  //   photos: [ '/image/Lemur.webp', '/image/Lemur1.webp', '/image/Lemur2.webp'],
  //   type: 'Rainforest Animals',
  //   // stock:"In Stock!",
  //   age:"5-12",
  //   link:"https://checkout.myzoobox.com/checkout/add/247020?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
  //   category:"One-time Boxes",
  //   description:"Leap into the Lemur EDventure, delving into the world of these intriguing primates native to Madagascar, known for their long tails and expressive faces. The box features a lemur stuffed animal, an educational science book, an anatomy poster & worksheet, a primate-focused STEM project, and a themed art project. Also included are an exclusive notebook, a lemur postcard, a collectible sticker, a Madagascar-inspired recipe, and QR printables."

    
  //   // Add other product details as needed
  // },
  
  {
    id: 'Monkey-EDventure',
    name: 'Monkey EDventure',
    image: '/image/pr15.png',
    price: '$39.95',
    photos: [ '/image/Monkey.webp', '/image/Monkey1.webp', '/image/Monkey2.webp'],
    type: 'Rainforest Animals',
    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/add/119414?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    category:"One-time Boxes",
    description:"Swing into the Monkey EDventure, exploring the playful and intelligent world of monkeys, primates known for their agility and social behavior. This box includes a wild republic monkey hugger, a detailed science book, an anatomy poster, and a creative art project. Plus, enjoy a monkey push puppet, a monkey postcard, a collectible sticker, and a fun recipe."

    // Add other product details as needed
  },

  // {
  //   id: 'Koala-EDventure',
  //   name: 'Koala EDventure',
  //   image: '/image/koala.jpg',
  //   price: '$39.95',
  //   photos: [ '/image/koala.jpg'],
  //   type: 'Fan Favorites',
  //   // stock:"In Stock!",
  //   age:"5-12",
  //   link:" https://checkout.myzoobox.com/checkout/buy/247024",
  //   category:"One-time Boxes",
  //   description:"Join the Koala Kapers, diving into the cozy and captivating world of koalas, gentle marsupials known for their cuddly charm and eucalyptus diet. This box includes an adorable Wild Republic koala hugger, an engaging science book, an informative anatomy poster, and a creative art project. Plus, enjoy a delightful koala push puppet, a koala postcard, a collectible sticker, and a tasty recipe."

  //   // Add other product details as needed
  // },

  {
    id: 'Hummingbird-EDventure',
    name: 'Hummingbird EDventure',
    image: '/image/hummingbird.png',
    price: '$39.95',
    photos: [ '/image/hummingbird.png'],
    type: 'Fan Favorites',
    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/buy/247022",
    category:"One-time Boxes",
    description:"Soar into the Hummingbird Haven, exploring the vibrant and fascinating world of hummingbirds, tiny birds known for their dazzling colors and incredible flying skills. This box includes a charming Wild Republic hummingbird hugger, an enlightening science book, an anatomy poster, and a creative art project. Plus, enjoy a whimsical hummingbird push puppet, a hummingbird postcard, a collectible sticker, and a refreshing recipe."

    // Add other product details as needed
  },

 
  {
    id: 'Moose-EDventure',
    name: 'Moose EDventure',
    image: '/image/moose.png',
    price: '$39.95',
    photos: [ '/image/moose.png'],
    type: 'Fan Favorites',
    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/buy/310564",
    category:"One-time Boxes",
    description:"Meander into the Moose Marvel, exploring the majestic and intriguing world of moose, the largest members of the deer family known for their impressive antlers and gentle demeanor. This box includes a captivating Wild Republic moose hugger, an insightful science book, an anatomy poster, and a creative art project. Plus, enjoy a fun moose push puppet, a moose postcard, a collectible sticker, and a hearty recipe."

    // Add other product details as needed
  },
  // {
  //   id: 'Penguin-EDventure',
  //   name: 'Penguin EDventure',
  //   image: '/image/pr16.png',
  //   price: '$39.95',
  //   photos: [ '/image/pr16.png', '/image/Penguin.webp', '/image/Penguin1.webp', '/image/Penguin2.webp'],
  //   type: 'Fan Favorites',
  //   age:"5-12",
  //   stock:"Low Stock Less Than 5",
  //   link:"https://checkout.myzoobox.com/checkout/add/247022",
  //   category:"One-time Boxes",
  //   description:"Slide into the chilly world of penguins with the Penguin EDventure box, celebrating these charismatic birds known for their waddling gait and swimming prowess. Inside, you'll find a wild republic penguin stuffed toy, an informational science book, an anatomy poster, and an penguin-tastic art project. Also included are a hatch penguin egg, a penguin postcard, a collectible sticker, and a yummy recipe."

  //   // Add other product details as needed
  // },
  {
    id: 'Sea-Lion-EDventure',
    name: 'Sea Lion EDventure',
    image: '/image/pr17.png',
    price: '$39.95',
    photos: [ '/image/Sealion.webp', '/image/Sealion1.webp', '/image/Sealion2.webp'],
    type: 'Ocean Animals',
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/add/247026",
    // stock:"In Stock!",
    category:"One-time Boxes",
    description:"Dive into the Sea Lion EDventure, learning about these playful marine mammals known for their barking sounds and agility in water. The box comes with a wild republic sea lion plush, an exclusive comprehensive science book, an anatomy poster & worksheet, a marine mammal STEM project, and an aquatic art project. Extras include an exclusive sea lion puzzle, a sea lion postcard, a collectible sticker, a themed recipe, and QR printables for more discovery.  "

    // Add other product details as needed
  },
  {
    id: 'Starfish-EDventure',
    name: 'Starfish EDventure',
    image: '/image/pr18.png',
    price: '$39.95',
    photos: [ '/image/staarfish1.png', '/image/starfish2.png'],
    type: 'Ocean Animals',
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/add/255002?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    // stock:"In Stock!",
    category:"One-time Boxes",
    description:"Explore the fascinating underwater world of Sea Stars with the Sea Star EDventure box, delving into these sea stars known for their regenerative abilities. This box includes a wild republic sea star stuffed animal, an insightful science book, an anatomy poster, and a marine art project. Also find a mini ocean puzzle, a sea star postcard, a collectible sticker, and a delicious smores sea star recipe. "

    // Add other product details as needed
  },
  //  {
  //   id: 'Stingray-EDventure',
  //   name: 'Stingray EDventure',
  //   image: '/image/pr19.png',
  //   price: '$39.95',
  //   photos: [ '/image/Stingray.webp', '/image/Stingray1.webp', '/image/Stingray2.webp'],
  //   type: 'Ocean Animals',
  //   age:"5-12",
  //   link:"https://checkout.myzoobox.com/checkout/add/247024?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
  //   // stock:"In Stock!",
  //   category:"One-time Boxes",
  //   description:"Glide into the Stingray EDventure, discovering the graceful world of stingrays, flat-bodied rays known for their long, whip-like tails. The box contains a wild republic stingray plush, a detailed science book, an anatomy poster,and an ocean-inspired art project. Plus, enjoy a bucket of ocean figurines, a stingray postcard, a collectible sticker, a coral reef-themed recipe."

  //   // Add other product details as needed
  // },
  // {
  //   id: 'Jaguar-EDventure',
  //   name: 'Jaguar EDventure',
  //   image: '/image/pr20.png',
  //   price: '$39.95',
  //   photos: [ '/image/Jaguar.webp', '/image/Jaguar1.webp', '/image/Jaguar2.webp'],
  //   type: 'Predators',
  //   link:"https://checkout.myzoobox.com/checkout/add/247030?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
  //   age:"5-12",
  //   // stock:"In Stock!",
  //   category:"One-time Boxes"

  //   // Add other product details as needed
  // },
  {
    id: 'Orangutan-EDventure',
    name: 'Orangutan EDventure',
    image: '/image/or.png',
    price: '$39.95',
    photos: [ '/image/Orangutan.webp', '/image/Orangutan2.webp', '/image/Orangutan3.webp','/image/Orangutan4.webp', '/image/Orangutan5.webp', '/image/Orangutan6.webp', '/image/Orangutan7.webp', '/image/Orangutan8.webp'],
    type: 'Rainforest Animals',
    link:"https://checkout.myzoobox.com/checkout/buy/294289",
    age:"5-12",
    category:"One-time Boxes"

    // Add other product details as needed
  },
  // {
  //   id: 'Kangaroo-EDventure',
  //   name: 'Kangaroo EDventure',
  //   image: '/image/pr22.png',
  //   price: '$39.95',
  //   photos: [ '/image/pr22.png'],
  //   type: 'Predators',
  //   stock:"Low Stock Less Than 10",
  //   link:"https://checkout.myzoobox.com/checkout/add/121029",
  //   age:"5-12",
  //   category:"One-time Boxes",
  //   description:"Discover the world of kangaroos, fascinating marsupials known for their powerful hind legs and unique pouches. Each Kangaroo EDventure box comes with a cuddly wild republic stuffed kangaroo, a book all about kangaroos, an anatomy poster, and a themed art project with all necessary supplies. Plus, enjoy an Australian Animals Tube, a postcard from a kangaroo, a collectible sticker, an authentic Australian recipe.",

  //   // Add other product details as needed
  // },
  {
    id: 'Arctic-Fox-Unit-Study',
    name: 'Arctic Fox Unit Study',
    image: '/image/d1.png',
    price: '$5.00',
    photos: [ '/image/d1.png'],

    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/buy/292221",

    category:"Digital Unit Studies"

    // Add other product details as needed
  },
  {
    id: 'Elephant-Unit-Study',
    name: 'Elephant Unit Study',
    image: '/image/d2.png',
    price: '$7.00',
    photos: [ '/image/d2.png'],
  
    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/buy/292188",

    category:"Digital Unit Studies"
    // Add other product details as needed
  },
  {
    id: 'Skunk-Unit-Study',
    name: 'Skunk Unit Study',
    image: '/image/d3.png',
    price: '$5.00',
    photos: [ '/image/d3.png'],

    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/292217",

    age:"5-12",
    category:"Digital Unit Studies"
    // Add other product details as needed
  },
  {
    id: 'Moose-Unit-Study',
    name: 'Moose Unit Study',
    image: '/image/d4.png',
    price: '$5.00',
    photos: [ '/image/d4.png'],
  
    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/buy/291857",

    category:"Digital Unit Studies"
    // Add other product details as needed
  },
  {
    id: 'Sea-horse-Unit-Study',
    name: 'Sea Horse Unit Study',
    image: '/image/d5.png',
    price: '$5.00',
    photos: [ '/image/d5.png'],

    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/buy/291011",

    category:"Digital Unit Studies"
    // Add other product details as needed
  },
  {
    id: 'Hummingbird-Unit-Study',
    name: 'Hummingbird Unit Study',
    image: '/image/d6.png',
    price: '$5.00',
    photos: [ '/image/d6.png'],
 
    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/buy/292215",

    category:"Digital Unit Studies"
    // Add other product details as needed
  },
  {
    id: 'Pangolin-Unit-Study',
    name: 'Pangolin Unit Study',
    image: '/image/d7.png',
    price: '$7.00',
    photos: [ '/image/d7.png'],

    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/283068",
    age:"5-12",
    category:"Digital Unit Studies"
    // Add other product details as needed
  },
  {
    id: 'Alligator-Unit-Study',
    name: 'Alligator Unit Study',
    image: '/image/d8.png',
    price: '$7.00',
    photos: ['/image/d8.png', '/image/Alligator.webp', '/image/Alligator1.webp', '/image/Alligator1.webp'],

    // stock:"In Stock!",
    age:"3-5",
    category:"Digital Unit Studies",
    link:"https://checkout.myzoobox.com/checkout/buy/283070",
    // Add other product details as needed
  },
];

export default productsData;
