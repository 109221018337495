import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import style from './shopV3.module.css'; // Import CSS module

const SearchBar = ({ productsData }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedAge, setSelectedAge] = useState('Select Club');
  const [selectedType, setSelectedType] = useState('Select Category');
  const [showAgeOptions, setShowAgeOptions] = useState(false);
  const [showTypeOptions, setShowTypeOptions] = useState(false);

  // Handle the custom age dropdown
  const handleAgeSelect = (age) => {
    setSelectedAge(age);
    setShowAgeOptions(false);
  };

  // Handle the custom type dropdown
  const handleTypeSelect = (type) => {
    setSelectedType(type);
    setShowTypeOptions(false);
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterResults = () => {
    if (selectedAge === 'Select Club' && selectedType === 'Select Category') {
      setShowDropdown(false);
      return;
    }

    const results = productsData.filter((product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedAge === 'Select Club' || selectedAge === 'All Ages' || product.age === selectedAge) &&
      (selectedType === 'Select Category' || product.type === selectedType)
    );
    setSearchResults(results);
    setShowDropdown(true);
  };

  const handleSearchClick = () => {
    filterResults();
  };

  return (
    <div className={`container ${style.searchProduct}`}>
      <h2>Match with their perfect EDventure</h2>
      <div className={style.container}>
        <div className={style.searchDis}>
          <p>I am shopping for a</p>
          <div
            className={style.customDropdown}
            onClick={() => setShowAgeOptions(!showAgeOptions)}
          >
            <div className={style.selectedOption}>
              {selectedAge === 'Select Club' ? (
                <>Select Club</>
              ) :
              selectedAge === 'All Ages' ? (
                <>All Ages</>
              ) : selectedAge === '3-5' ? (
                <>
                  <img src="/image/icon/cubb.svg" alt="Cub" /> 3-5 Year Old
                </>
              ) : (
                <>
                  <img src="/image/icon/zooo.svg" alt="Zoo" /> 5-12 Year Old
                </>
              )}
              <span className={style.arrow}></span>
            </div>
            {showAgeOptions && (
              <div className={style.optionsList}>
                <div onClick={() => handleAgeSelect('Select Club')} className={style.option}>
                  Select Club
                </div>
                <div onClick={() => handleAgeSelect('3-5')} className={style.option}>
                  <img src="/image/icon/cubb.svg" alt="Cub" /> 3-5 Year Old
                </div>
                <div onClick={() => handleAgeSelect('5-12')} className={style.option}>
                  <img src="/image/icon/zooo.svg" alt="Zoo" /> 5-12 Year Old
                </div>
              </div>
            )}
          </div>

          <p>looking to explore the</p>
          <div
            className={style.customDropdown}
            onClick={() => setShowTypeOptions(!showTypeOptions)}
          >
            <div className={style.selectedOption}>
              {selectedType === 'Select Category' ? (
                <>Select Category</>
              ) : selectedType === 'Ocean Animals' ? (
                <>
                  <img src='/image/ocean.png' alt='Ocean' /> Ocean Animals
                </>
              ) : selectedType === 'African Animals' ? (
                <>
                  <img src='/image/african.png' alt='African' /> African Animals
                </>
              ) : selectedType === 'Predators' ? (
                <>
                  <img src='/image/predator.png' alt='Predator' /> Predators
                </>
              ) : selectedType === 'Rainforest Animals' ? (
                <>
                  <img src='/image/rainforest.png' alt='Rainforest' /> Rainforest Animals
                </>
              ) : selectedType === 'Animals that Fly' ? (
                <>
                  <img src='/image/fly.png' alt='Fly' /> Animals That Fly
                </>
              ) : (
                selectedType
              )}
              <span className={style.arrow}></span>
            </div>
            {showTypeOptions && (
              <div className={`${style.optionsList} ${style.optionsListTwo}`}>
                <div onClick={() => handleTypeSelect('Select Category')} className={style.option}>
                  Select Category
                </div>
                <div onClick={() => handleTypeSelect('Ocean Animals')} className={style.option}>
                  <img src='/image/ocean.png' alt='Ocean' /> Ocean Animals
                </div>
                <div onClick={() => handleTypeSelect('African Animals')} className={style.option}>
                  <img src='/image/african.png' alt='African' /> African Animals
                </div>
                <div onClick={() => handleTypeSelect('Predators')} className={style.option}>
                  <img src='/image/predator.png' alt='Predator' /> Predators
                </div>
                <div onClick={() => handleTypeSelect('Rainforest Animals')} className={style.option}>
                  <img src='/image/rainforest.png' alt='Rainforest' /> Rainforest Animals
                </div>
                <div onClick={() => handleTypeSelect('Animals that Fly')} className={style.option}>
                  <img src='/image/fly.png' alt='Fly' /> Animals That Fly
                </div>
              </div>
            )}
          </div>

          <div className={style.searchIcon} onClick={handleSearchClick}>
            <img src="/image/search.svg" alt="Search" />
          </div>
        </div>

        {showDropdown && (
          <div className={style.dropdownMenu}>
            {searchResults.length > 0 ? (
              searchResults.map((product) => (
                <div key={product.id} className={style.dropdownItem}>
                  <Link to={`/product/${product.id}`} onClick={() => animateScroll.scrollToTop()}>
                    {product.name}
                  </Link>
                </div>
              ))
            ) : (
              <div className={style.dropdownItem}>
                No results found for "{searchTerm}"
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
