import React, { useState } from 'react';
import styles from './shopV3.module.css'; // Import the CSS module

const Filters = ({ filters, setFilters }) => {
  // State for toggling filter sections
  const [isAgeOpen, setIsAgeOpen] = useState(true);
  const [isCategoryAnimalsOpen, setIsCategoryAnimalsOpen] = useState(false);
  const [isCategoryTypeOpen, setIsCategoryTypeOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Toggle functions
  const toggleAge = () => setIsAgeOpen(!isAgeOpen);
  const toggleCategoryAnimals = () => setIsCategoryAnimalsOpen(!isCategoryAnimalsOpen);
  const toggleCategoryType = () => setIsCategoryTypeOpen(!isCategoryTypeOpen);

  // Handle filter changes
  const handleAgeChange = (age) => {
    if (filters.age.includes(age)) {
      setFilters({
        ...filters,
        age: filters.age.filter((a) => a !== age),
      });
    } else {
      setFilters({
        ...filters,
        age: [...filters.age, age],
      });
    }
  };
  const toggleFilterPopup = () => setIsOpen(!isOpen);

  const handleCategoryChange = (type) => {
    if (filters.type.includes(type)) {
      setFilters({
        ...filters,
        type: filters.type.filter((c) => c !== type),
      });
    } else {
      setFilters({
        ...filters,
        type: [...filters.type, type],
      });
    }
  };

  const handleTypeChange = (category) => {
    if (filters.category.includes(category)) {
      setFilters({
        ...filters,
        category: filters.category.filter((t) => t !== category),
      });
    } else {
      setFilters({
        ...filters,
        category: [...filters.category, category],
      });
    }
  };
console.log(filters,"asdadasdas")
  return (
            <>
            
    <button className={styles.filterButton} onClick={toggleFilterPopup}>
    Filter
  </button>

  
    <div className={styles.filters}>
      {/* Age Filter */}
      <div className={styles.filterSection}>
        <div className={styles.filterHeader} onClick={toggleAge}>
          <h3>Age</h3>
          <span className={`${styles.arrow} ${isAgeOpen ? styles.arrDown : styles.arrUp}`}> <img src='/image/arr.png'/> </span>
        </div>
        <div className={`${styles.filterOptions} ${isAgeOpen ? styles.open : styles.closed}`}>
          <label>
            
            <input
              type="checkbox"
              checked={filters.age.includes('3-5')}
              onChange={() => handleAgeChange('3-5')}
            />
             <div className={styles.agesIcon}>
              <p> 3-5   </p>
              <img src='/image/cubi.svg'/>
             </div>
          </label>
          <label>
            <input
              type="checkbox"
              checked={filters.age.includes('5-12')}
              onChange={() => handleAgeChange('5-12')}
            />
           
            <div className={styles.agesIcon}>
              <p>  5-12</p>
              <img src='/image/zooi.svg'/>
             </div>
          </label>
        </div>
      </div>

      {/* Category: Animals Filter */}
      <div className={styles.filterSection}>
        <div className={styles.filterHeader} onClick={toggleCategoryAnimals}>
          <h3>Category</h3>
          <span className={`${styles.arrow} ${isCategoryAnimalsOpen ? styles.arrDown : styles.arrUp}`}> <img src='/image/arr.png'/></span>
        </div>
        <div className={`${styles.filterOptions} ${isCategoryAnimalsOpen ? styles.open : styles.closed}`}>
          <label>
            <input
              type="checkbox"
              checked={filters.type.includes('African Animals')}
              onChange={() => handleCategoryChange('African Animals')}
            />
            African Animals
          </label>
          <label>
            <input
              type="checkbox"
              checked={filters.type.includes('Predators')}
              onChange={() => handleCategoryChange('Predators')}
            />
            Predators
          </label>
          <label>
            <input
              type="checkbox"
              checked={filters.type.includes('Rainforest Animals')}
              onChange={() => handleCategoryChange('Rainforest Animals')}
            />
            Rainforest Animals
          </label>
          <label>
            <input
              type="checkbox"
              checked={filters.type.includes('Animals that Fly')}
              onChange={() => handleCategoryChange('Animals that Fly')}
            />
            Animals that Fly
          </label>
          <label>
            <input
              type="checkbox"
              checked={filters.type.includes('Ocean Animals')}
              onChange={() => handleCategoryChange('Ocean Animals')}
            />
            Ocean Animals
          </label>
        </div>
      </div>

      {/* Category: Type Filter */}
      <div className={styles.filterSection}>
        <div className={styles.filterHeader} onClick={toggleCategoryType}>
          <h3>Category</h3>
          <span className={`${styles.arrow} ${isCategoryTypeOpen ? styles.arrDown : styles.arrUp}`}> <img src='/image/arr.png'/></span>
        </div>
        <div className={`${styles.filterOptions} ${isCategoryTypeOpen ? styles.open : styles.closed}`}>
          <label>
            <input
              type="checkbox"
              checked={filters.category.includes('One-time Boxes')}
              onChange={() => handleTypeChange('One-time Boxes')}
            />
            EDventure Boxes
          </label>
          <label>
            <input
              type="checkbox"
              checked={filters.category.includes('Digital Unit Studies')}
              onChange={() => handleTypeChange('Digital Unit Studies')}
            />
            Digital Unit Studies
          </label>
        </div>
      </div>
    </div>
            </>
  );
};

export default Filters;
