import React, { useState } from 'react';
import Filters from './filtersV3';
import ProductList from './productListV3';
import productsData from '../mainShop/ProductsData';
import style from "./shopV3.module.css"
import Trusted from '../../component/trusted/Trusted';



const ShopV3 = () => {
  const [filters, setFilters] = useState({
    age: [],
    category: [],
    type: [], // Add type to filters
  });

  const filteredProducts = productsData.filter((product) => {
    const ageMatch = filters.age.length
      ? filters.age.includes(product.age)
      : true;

    const categoryMatch = filters.category.length
      ? filters.category.some((cat) => product.category.includes(cat))
      : true;

    const typeMatch = filters.type.length
      ? filters.type.includes(product.type) // Use product.type instead of clubType
      : true;

    return ageMatch && categoryMatch && typeMatch;
  });

  return (
        <>
        <div className={style.edventureBanner}>
          <h1>All Animal EDventures</h1>
        </div>
        <div className={`container ${style.edventurePage}`}>
      <div className="filters-section">
        <Filters filters={filters} setFilters={setFilters} />
      </div>
      <div className="products-section">
        <ProductList products={filteredProducts} />
      </div>
    </div>

    <Trusted/>
        </>
  );
};

export default ShopV3;