import React, { useEffect, useRef, useState } from "react";
import style from "./CopyStore.module.css";
import { useLocation } from "react-router-dom";

const UnitStudy = () => {
  const [isOpen, setOpen] = useState(false);
  const [active, setActive] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const productsEight = {
    title: "Digital Unit Studies",
    box: [
      { id: 1, club: "Zoologist Club", image: "/image/d1.png", name: "Arctic Fox Unit Study", price: "$5.00", link: "https://checkout.myzoobox.com/checkout/buy/292221" },
      { id: 2, image: "/image/d2.png", club: "Cub Club", name: "Elephant Unit Study", price: "$7.00", link: "https://checkout.myzoobox.com/checkout/buy/292188" },
      { id: 3, image: "/image/d3.png", club: "Zoologist Club", name: "Skunk Unit Study", price: "$5.00", link: "https://checkout.myzoobox.com/checkout/buy/292217" },
      { id: 4, image: "/image/d4.png", club: "Zoologist Club", name: "Moose Unit Study", price: "$5.00", link: "https://checkout.myzoobox.com/checkout/buy/291857" },
      { id: 5, image: "/image/d5.png", club: "Zoologist Club", name: "Sea Horse Unit Study", price: "$5.00", link: "https://checkout.myzoobox.com/checkout/buy/291011" },
      { id: 6, image: "/image/d6.png", club: "Zoologist Club", name: "Hummingbird Unit Study", price: "$5.00", link: "https://checkout.myzoobox.com/checkout/buy/292215" },
      { id: 7, image: "/image/d7.png", club: "Cub Club", name: "Pangolin Unit Study", price: "$7.00", link: "https://checkout.myzoobox.com/checkout/buy/283068" },
      { id: 8, image: "/image/d8.png", club: "Cub Club", name: "Alligator Unit Study", price: "$7.00", link: "https://checkout.myzoobox.com/checkout/buy/283070" },
    ],
  };

  useEffect(() => {
    setFilteredProducts(productsEight.box);
  }, []);

  const handleTabClick = (index) => {
    setActive(index);
    if (index === 0) {
      setFilteredProducts(productsEight.box);
    } else if (index === 1) {
      setFilteredProducts(productsEight.box.filter(product => product.club === "Cub Club"));
    } else if (index === 2) {
      setFilteredProducts(productsEight.box.filter(product => product.club === "Zoologist Club"));
    }
  };

  const sectionOffer = useRef(null);
  const sectionOfferTwo = useRef(null);
  const sectionOfferThree = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      window.subblyConfig = {
          apiKey: '52d2a9b4-a884-42cb-83a9-cfb67f347926',
          settings: {
              interceptProductLinks: true,
              cartCounterEl: '.subbly-cart-product-count',
              cartToggleEl: '.subbly-cart',
              cartButton: true,
          },
      };
    `;
    document.body.appendChild(script);

    const subblyScript = document.createElement('script');
    subblyScript.type = 'module';
    subblyScript.src = 'https://assets.subbly.co/cart/cart-widget.js';
    document.body.appendChild(subblyScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(subblyScript);
    };
  }, []);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sectionToScroll = queryParams.get("section");

    if (sectionToScroll === "40") {
      scrollToSection(sectionOffer);
    } else if (sectionToScroll === "25") {
      scrollToSection(sectionOfferTwo);
    } else if (sectionToScroll === "10") {
      scrollToSection(sectionOfferThree);
    }
  }, [location.search]);

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`${style.plansBox} ${style.storeSix}`}>
      <h1>{productsEight.title}</h1>
      <div className={`${style.tabsDis} `}>
        <p className={active === 0 ? style.active : ""} onClick={() => handleTabClick(0)}>
          All
        </p>
        <p className={`${style.tabDis}  ${active === 1 ? style.active : ""}`} onClick={() => handleTabClick(1)}>
          <img src="/image/icon/cubb.svg" /><span>Cub Club</span> <span>3-5</span>
        </p>
        <p className={`${style.tabDis} ${active === 2 ? style.active : ""}`} onClick={() => handleTabClick(2)}>
          <img src="/image/icon/zooo.svg" /><span>Zoologist Club</span><span>5-11</span>
        </p>
        <span className={`${style.spanTab} ${active === 0 ? style.activeSpan : active === 1 ? style.activeSpanTwo : style.activeSpanThree}`}></span>
      </div>

      <div className="container">
        <div className={style.storeDis}>
          {filteredProducts.map((item) => (
            <div key={item.id}>
              <img src={item.image} alt={item.name} />
              <h3>{item.name}</h3>
              <p className={item.club === "Cub Club" ? style.cubColor : style.zooColor}>{item.club}</p>
              <p>{item.price} <i>In Stock</i></p>
              <a href={item.link}>Add to Cart</a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UnitStudy;
