import React, { useEffect, useState } from "react";
import style from "./Halloween.module.css";
import { useLocation } from 'react-router-dom'; // If you're using React Router

const Halloween = (ref) => {
  const [selectedItem, setSelectedItem] = useState(null); // For storing the selected box item
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state
  const [activeTab, setActiveTab] = useState("tab1"); // Active tab state

  const box = [
    {
      image: "/image/h333.png",
      name: "Introduction to Spiders",
      text: "A My ZOO Box Unit Study",
      
      price:"$8.00",
      link: "https://checkout.myzoobox.com/checkout/buy/330623",
      bigText: `<b>Introduction to Spiders Unit Study - Digital Product for My ZOO Box!</b>
<br /><br />
Dive deep into the world of arachnids with the Introduction to Spiders Unit Study, perfect for students fascinated by creepy-crawly creatures. This study unravels the mysteries of various spider species, from the common house spider to the fearsome tarantula.
<br /><br />Key Features:<br />
<ul>
  <li><strong>Interactive Learning:</strong> Engaging activities like quizzes, crafts, and reading exercises guide students through the life cycles, habitats, and web-building techniques of different spider species.</li>
  <li><strong>Focus on 15 Unique Spiders:</strong> Each spider species is explored in detail, covering their habitats, prey, and the unique adaptations that make them incredible hunters.</li>
  <li><strong>Rich Multimedia Content:</strong> Vivid imagery and informative diagrams complement spider-related facts, helping students visualize the anatomy and behavior of spiders like the orb-weaver and black widow.</li>
  <li><strong>Hands-On Projects:</strong> From building 3D spider models to weaving their own webs, students will participate in creative challenges that boost understanding of spider biology and behaviors.</li>
  <li><strong>Educator Resources:</strong> The study includes guides, reading materials, and project ideas that make it easy for teachers and parents to provide a comprehensive look into the world of spiders.</li>
  <li><strong>Perfect for My ZOO Box:</strong> This unit study complements the My ZOO Box subscription, enriching the learning experience with an in-depth focus on arachnids.</li>
</ul><br />
What Your Students Will Learn:<br />
<ul>
  <li>Explore the biology and anatomy of spiders, including their webs, venom, and hunting techniques.</li>
  <li>Learn about the different species of spiders and their role in balancing ecosystems.</li>
  <li>Engage in STEAM projects, creative challenges, and hands-on activities inspired by spiders.</li>
  <li>Understand the importance of spider conservation and dispel myths about these misunderstood creatures.</li>
</ul><br />
The Introduction to Spiders Unit Study is an exciting way to combine hands-on learning with the thrills of discovering the secret lives of arachnids. Whether it's the tarantula or the jumping spider, students will develop a new appreciation for these vital yet often feared animals.
<br /><strong>Order the My ZOO Box Introduction to Spiders Unit Study today and introduce your students to the amazing world of spiders!</strong>
`

    },
    {
      image: "/image/h2.png",
      name: "Introduction to Bats",
      text: "A My ZOO Box Unit Study",
      price:"$8.00",

      link: "https://checkout.myzoobox.com/checkout/buy/263327",
      bigText: `<b>Introduction to Bats Unit Study - Digital Product for My ZOO Box!</b>
      <br /><br />
      Embark on a fascinating journey into the world of bats with our Introduction to Bats Unit Study, designed for elementary-age students. This comprehensive study sheds light on these nocturnal creatures and their crucial role in ecosystems while dispelling myths and uncovering their remarkable adaptations.
      <br />
      <br />Key Features:<br />
      <ul>
        <li><strong>Interactive Learning:</strong> Engage with activities like bat-themed quizzes, coloring pages, reading passages, and hands-on projects to explore bat species, their habitats, and their mysterious nocturnal behaviors.</li>
        <li><strong>In-Depth Bat Study:</strong> Discover the amazing abilities of bats, from echolocation to their migratory patterns. Learn about different species, including the fruit bat, vampire bat, and others, with fun facts and spooky myths debunked.</li>
        <li><strong>Rich Multimedia Content:</strong> The study includes captivating images, diagrams, and educational videos that make learning about bats immersive and fun.</li>
        <li><strong>Hands-On Projects:</strong> Get creative with bat-related STEAM challenges, from designing bat houses to studying their flight patterns and creating bat-themed crafts.</li>
        <li><strong>Educator Resources:</strong> Access teaching guides, reading passages, and discussion prompts that make it easy for educators and parents to facilitate a bat-focused learning experience.</li>
        <li><strong>Perfect for My ZOO Box:</strong> This bat-themed unit is the perfect addition to your My ZOO Box subscription, expanding your child’s knowledge about these mysterious creatures.</li>
      </ul><br />
      What Your Students Will Learn:<br />
      <ul>
        <li>Understand the importance of bats in maintaining healthy ecosystems.</li>
        <li>Explore bat anatomy, adaptations, and their nocturnal habits.</li>
        <li>Engage in creative projects and critical thinking activities focused on bat conservation.</li>
        <li>Discover fascinating myths and facts about these often-misunderstood creatures.</li>
      </ul><br />
      The Introduction to Bats Unit Study provides an exciting and educational look into one of nature's most fascinating animals. From vampire bats to the flying fox, students will discover the wonder of bats and their important role in our world.<br />
      <strong>Order the My ZOO Box Introduction to Bats Unit Study today and let your students uncover the secrets of these amazing nocturnal creatures!</strong>`

    },
    {
      image: "/image/h1.png",
      name: "Spooky Safari",
      text: "A My ZOO Box Unit Study",
      price:"$8.00",

      link: "https://checkout.myzoobox.com/checkout/buy/329846",
      bigText: `<b>Introducing the Spooky Safari Unit Study - Digital Product for My ZOO Box!</b><br /><br />
      Embark on a thrilling and eerie adventure with our Spooky Safari Unit Study, designed exclusively for elementary-age students. This comprehensive study dives into the mysterious and spooky world of 20 unique animals, from nocturnal hunters to animals with strange adaptations. Ideal for curious kids who love a mix of wildlife and all things spooky, this interactive digital resource will spark their imaginations while teaching them fascinating facts about some of nature's creepiest creatures.<br />
      <br />Key Features:<br />
      <ul>
        <li><strong>Interactive Learning:</strong> Our Spooky Safari is packed with engaging, interactive activities, including coloring pages, reading passages, quizzes, and hands-on projects. These activities will immerse students in the strange and eerie worlds of creatures like the vampire bat, black mamba, ghost crab, and more.</li>
        <li><strong>20 Spooky Creatures:</strong> Dive into the fascinating lives of 20 mysterious animals. Each creature is explored in detail, from their habitats and diets to the spooky myths and adaptations that make them unique. Students will learn about nocturnal habits, predator-prey relationships, and the importance of conservation.</li>
        <li><strong>Rich Multimedia Content:</strong> The Spooky Safari includes vibrant images, detailed maps, and spooky-themed infographics to enhance learning. With resources like Venn diagrams for comparing animals and STEAM challenges to spark creativity, this study brings these animals to life in a fun and educational way.</li>
        <li><strong>Hands-On Projects:</strong> Creativity and critical thinking come together in projects such as building a spooky safari truck, designing eerie animal habitats, and crafting your own spooky creature out of recycled materials. There are even challenges like creating instruments inspired by animal sounds or designing bridges for spooky safaris.</li>
        <li><strong>Educator Resources:</strong> Comprehensive teaching guides, reading passages with questions, and discussion prompts make it easy for educators and parents to guide students through the Spooky Safari. Whether in the classroom or at home, you’ll have all the tools you need for an engaging learning experience.</li>
        <li><strong>Suitable for All Learning Styles:</strong> This unit study is designed for various learning styles, with activities that appeal to visual learners, hands-on students, and those who love storytelling and creative writing. Every child can find something to enjoy and engage with in this spooky-themed unit.</li>
        <li><strong>Perfect for My ZOO Box:</strong> The Spooky Safari Unit Study is the ideal companion to a My ZOO Box subscription, enriching your child’s learning experience by diving deep into the world of mysterious creatures. It's the perfect way to extend your child’s wildlife education with a spooky twist.</li>
      </ul><br />
      What Your Students Will Learn:<br />
      <ul>
        <li>Learn about 20 spooky animals, including their unique characteristics and habitats.</li>
        <li>Explore how these creatures survive in the wild, with eerie adaptations and nocturnal habits.</li>
        <li>Discover fascinating life cycles and the role these animals play in their ecosystems.</li>
        <li>Engage in hands-on STEAM challenges, creative projects, and storytelling activities inspired by spooky animals.</li>
        <li>Understand the importance of animal conservation, even for creatures that may seem creepy or misunderstood.</li>
      </ul><br />
      Spooky Safari Unit Study offers an exciting way to combine learning with the thrills of the spooky season. From the mysterious vampire squid to the eerie tarantula, students will uncover the surprising science behind animals that are often feared or misunderstood. With engaging activities and fascinating facts, your students will learn to appreciate the creepy creatures that help keep our ecosystems in balance.<br />
      <strong>Order the My ZOO Box Spooky Safari Unit Study today and let your students dive into a spine-tingling, educational adventure filled with spooky creatures and wild discoveries!</strong>`
    
    },
  ];



  const boxTwo = [
    {
      image: "/image/b1.png",
      name: "Learn with Spiders",
      text: "A My ZOO Box Unit Study",
      price:"$6.00",
      link: "https://checkout.myzoobox.com/checkout/buy/330623",
      bigText: `<b>Learn with Spiders - A My ZOO Box Cub Club Activity Pack</b><br /><br />
      <strong>Key Features:</strong><br />
      <ul>
        <li><strong>Learn About Spiders:</strong> Discover 6 fun facts about spiders, from their eight legs and invertebrate bodies to their incredible ability to thrive in various habitats.</li>
        <li><strong>Hands-On Learning:</strong> Kids can trace the word "spider," color their own spider, and practice early literacy skills with spider-themed word and shape tracing activities.</li>
        <li><strong>Spider Snapshot:</strong> Color the camera and draw a spider inside the frame. Perfect for reinforcing both creativity and spider facts!</li>
        <li><strong>Shape & Number Games:</strong> Trace spider shapes, count spiders, and circle all the number eights to reinforce number recognition and early math skills.</li>
        <li><strong>Bonus Activity: Safe from the Spider Web Game</strong><br />Materials: Yarn or string, masking tape, an open play area, and a tree or other anchor point. A fun and active game for preschoolers that also enhances motor skills!</li>
      </ul><br />
      <strong>Suggested Uses:</strong><br />
      <ul>
        <li><strong>Spider Play Mats:</strong> Use playdough, pom-poms, or crayons to create spider shapes and webs. These mats can be laminated for repeated use.</li>
        <li><strong>Creative Crafting:</strong> Cut and paste activities help kids create spiders and webs, while additional coloring sheets inspire artistic expression.</li>
        <li><strong>Literacy Practice:</strong> Trace letters, practice sight words, and learn to write your name with spider-themed worksheets.</li>
      </ul><br />
      This My ZOO Box Cub Club activity pack offers an engaging way for preschoolers to explore the world of spiders while building essential early learning skills!<br />`

    },
    {
      image: "/image/b2.png",
      name: "Learn with Bats",
      text: "A My ZOO Box Unit Study",
      price:"$6.00",
      link: "https://checkout.myzoobox.com/checkout/buy/330667",
      bigText: `<b>Learn with Bats - A My ZOO Box Cub Club Activity Pack</b><br /><br />
      These educational materials are perfect for personal, classroom, small learning groups, or homeschool co-ops. Dive into the fascinating world of bats with interactive activities designed specifically for preschoolers. This bat-themed activity pack is filled with hands-on learning experiences and fun games to help young learners explore the wonders of these nocturnal mammals!<br /><br />
      <strong>Key Features:</strong><br />
      <ul>
        <li><strong>Learn About Bats:</strong> Explore six fascinating facts about bats, from their upside-down sleeping habits to their unique wings and echolocation abilities.</li>
        <li><strong>Hands-On Activities:</strong> Trace the word "bat," color bat-themed images, and practice early literacy with activities focused on the letter "B."</li>
        <li><strong>Bats at Play Game:</strong> Create a fun and active learning experience by dividing students into two "bat colonies" and playing an interactive game where learners catch soft balls and grow their bat colony.</li>
        <li><strong>Math Fun:</strong> Fill in ten frames to match the number of bats, and practice counting skills with bat-themed math activities.</li>
        <li><strong>Creative Play Mats:</strong> Engage your preschoolers with bat play mats, where they can use playdough, pom-poms, or crayons to create bats and their habitats.</li>
        <li><strong>Bonus Activity: Bat Food & Pollination</strong><br />Materials: Use printable cut-outs to fill bat food bowls with different items such as fruit or insects based on the bat’s diet. Learn how bats help pollinate plants like bananas, mangoes, and avocados.</li>
      </ul><br />
      <strong>Suggested Uses:</strong><br />
      <ul>
        <li>Practice writing sight words and vocabulary related to bats.</li>
        <li>Create a story or poem inspired by bats.</li>
        <li>Use manipulatives like playdough or crayons to engage in creative bat-themed projects.</li>
        <li>Cut and paste bats in order from smallest to largest and color in shapes to learn about bat habitats around the world.</li>
      </ul><br />
      This bat activity pack offers a fun and interactive way for young learners to explore the incredible world of bats while building early literacy and math skills!<br />`


    },
    
  ];
  const openModal = (item) => {
    setSelectedItem(item); // Set the selected item
    setIsModalOpen(true);  // Open the modal
  };

  const closeModal = () => {
    setSelectedItem(null); // Clear the selected item
    setIsModalOpen(false); // Close the modal
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains(style.modal)) {
      closeModal(); // Close modal if clicking outside modal content
    }
  };
  const location = useLocation(); // Access the location object

  useEffect(() => {
    // Check for the spooky-sale query parameter
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('spooky-sale')) {
      // Scroll to the section if the parameter is present
      ref.refSeven.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.search]); // Dependency array includes location.search to run when it changes

  return (
    <div className={style.hallowenBck} ref={ref.refSeven}>
      <div className="container">
        <div className={style.spookTitle}>
          <img src="/image/spok.png" alt="Halloween Title" />
        </div>
        <div className={style.tabs}>
        <button
          className={`${style.zooTab} ${activeTab === "tab1" ? style.activeTab : ""}`}
          onClick={() => setActiveTab("tab1")}
        >
            {activeTab === "tab1" ?   <img src="/image/icon/zooo.svg" alt="Zoologist Club Icon" /> :   <img src="/image/zo.svg" alt="Zoologist Club Icon" />}
           <div><p>Zoologist Club</p><p>Ages 5-11</p></div>
        </button>
        <button
          className={`${style.cubTab} ${activeTab === "tab2" ? style.activeTab : ""}`}
          onClick={() => setActiveTab("tab2")}
        >
             {activeTab === "tab2" ?  <img src="/image/icon/cubb.svg" alt="Zoologist Club Icon" /> :   <img src="/image/co.svg" alt="Zoologist Club Icon" />}
          <div><p>Cub Club</p><p>Ages 3-5</p></div>
         </button>
      </div>    
      {activeTab === "tab1" && (

        <div className={style.hallDis}>
       
{box.map((item, index) => (
    <div key={index} className={style.boxCard}>
      <div className={style.digitalImage}>
        <img src={item.image} alt={item.name} />
      </div>
      <div className={style.clubIcon}>
      <img src="/image/icon/zooo.svg" alt="Zoologist Club Icon" /> <p>Zoologist Club</p>

        </div>
      <h2>{item.name}</h2>
      <p>{item.text}</p>
      <span className={style.price}>{item.price}</span>
      <a href={item.link}>Add to Cart</a>
      <p className={style.openPopUp} onClick={() => openModal(item)}>
        Learn More
      </p>
    </div>
  ))}

       

        </div>
 )}

{activeTab === "tab2" && (

<div className={`${style.hallDisTwo} ${style.hallDis}`}>

{boxTwo.map((item, index) => (
<div key={index} className={style.boxCard}>
<div className={style.digitalImage}>
<img src={item.image} alt={item.name} />
</div>
<div className={style.clubIcon}>
      <img src="/image/icon/cubb.svg" alt="Cub Club Icon" /> <p> Cub Club</p>

        </div>
<h2>{item.name}</h2>
<p>{item.text}</p>
<span className={style.price}>{item.price}</span>

<a href={item.link}>Add to Cart</a>
<p className={style.openPopUp} onClick={() => openModal(item)}>
Learn More
</p>
</div>
))}



</div>
)}
{activeTab === "tab1" ? (

      <div className={style.bundledDis}>
        <span className={style.bundleDiscount}>-20%</span>
           <div className={style.bundledImage}>
            <img src="/image/bu.png"/>
           </div>
           <div className={style.bundledContent}>
            <h3>SPOOKY BUNDLE            </h3>
            <h2>Get All 3, for <span>$24</span></h2>
            <h1>$20</h1>
            <a href="https://checkout.myzoobox.com/checkout/buy/330682 ">Add to Cart</a>
            </div>

      </div>

):(  <div className={`${style.bundledDis} ${style.bundleCub}`}>
    <span className={style.bundleDiscount}>-20%</span>
       <div className={style.bundledImage}>
        <img src="/image/bu2.png"/>
       </div>
       <div className={style.bundledContent}>
        <h3>SPOOKY BUNDLE        </h3>
        <h2>Get BOTH, for <span>$12</span></h2>
        <h1>$10</h1>
        <a href="https://checkout.myzoobox.com/checkout/buy/330679">Add to Cart</a>
        </div>

  </div>

) }
      </div>

      {/* Modal */}
      {isModalOpen && selectedItem && (
        <div className={style.modal} onClick={handleOverlayClick}>
          <div className={style.modalContent}>
            <button className={style.closeModal} onClick={closeModal}>
              &times;
            </button>
            <div className={style.modalImage}>
              <img src={selectedItem.image} alt={selectedItem.name} />
            </div>
            <div className={style.modalPrice}>
                <div>
                <h2>{selectedItem.name}</h2>
            <p className={style.modalSub}>{selectedItem.text}</p>

                    </div>
                    <span>{selectedItem.price}</span>
            </div>
            <div
              className={style.modalBigText}
              dangerouslySetInnerHTML={{ __html: selectedItem.bigText }}
            ></div>

            <div className={style.clicking}>
                <p>By clicking Add to Cart, you acknowledge that the Unit Study will be delivered via email only and that no physical product will be shipped. Due to the digital nature of this product, all sales are final, and no refunds will be provided.</p>
                </div>
            <a href={selectedItem.link} className={style.modalAddToCart}>
              Add to Cart
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Halloween;