import React, { useRef, useState } from 'react';
import style from "./shopV3.module.css"
import KidsTestimonials from '../../component/kidTestimonials/KidTestimonials';
import Newsletter from '../../component/newsletter/Newsletter';
import productsData from '../mainShop/ProductsData';
import { animateScroll } from "react-scroll";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';
import ProductCard from './productCardV3';
import SearchBar from './serchBarV3';
import { Link } from 'react-router-dom';
const AllShopV3 = () => {
    const [filters, setFilters] = useState({
        age: [],
        category: [],
        type: [], // Add type to filters
      });
    
      const filteredProducts = productsData.filter((product) => {
        const ageMatch = filters.age.length
          ? filters.age.includes(product.age)
          : true;
    
        const categoryMatch = filters.category.length
          ? filters.category.some((cat) => product.category.includes(cat))
          : true;
    
        const typeMatch = filters.type.length
          ? filters.type.includes(product.type) // Use product.type instead of clubType
          : true;
    
        return ageMatch && categoryMatch && typeMatch;
      });
const fun = [
    {
        image:'/image/fun.png',
        years:"3-5",
        club:"Cub",
        link:"/cub-club"
    },
    {
        image:'/image/fun1.png',
        years:"5-12",
        club:"Zoologist",
        link:"/zoologist-club"
    },
]
  return (
            <>
            <div className={`container allShopV ${style.shopBanner}`}>
            <Swiper
  pagination={true}
  modules={[Pagination]}
  className="mySwiper"
  breakpoints={{
    // when the window width is >= 768px (tablet/desktop)
    768: {
      slidesPerView: 1,
    },
    // when the window width is < 768px (mobile)
    0: {
      slidesPerView: 1,
    },
  }}
>
<SwiperSlide className={style.swiperDes}>
     <Link to="/" >
       <img src='/image/swipOne.png'/>
     </Link>
  </SwiperSlide>
  <SwiperSlide  className={style.swiperDes}>
  <Link to="/">
  <img src='/image/swipTwo.png'/>
  </Link>
  </SwiperSlide>
  <SwiperSlide  className={style.swiperDes}>
  <Link to="/">
  <img src='/image/swipThree.png'/>
  </Link>
  </SwiperSlide>


  <SwiperSlide className={style.swiperMob}>
     <Link to="/" >
       <img src='/image/smob.png'/>
     </Link>
  </SwiperSlide>
  <SwiperSlide  className={style.swiperMob}>
  <Link to="/">
  <img src='/image/smob2.png'/>
  </Link>
  </SwiperSlide>
  <SwiperSlide  className={style.swiperMob}>
  <Link to="/">
  <img src='/image/smob3.png'/>
  </Link>
  </SwiperSlide>
</Swiper>
            </div>
             <div className={style.animalFunContainer}>
             <div className={style.animalFun}>
                <h2><span>Animal fun & learning</span> for all ages</h2>
                 <div className={style.animalFunDis}>
                    {fun.map((item) =>{
                        return(
                            <Link to={item.link} className={`${style.animalFunBox} ${style[item.club]}`} onClick={() => animateScroll.scrollToTop()}>
                            <div>
                                <img src={item.image} alt='fun'/>
                                <p>Curated for {item.years} Year Olds</p>
                                <h3>Explore the <span>{item.club} Club</span> EDventures</h3>
                            </div>
                            <p className={style.funShop}>
                                Shop for Ages {item.years} 
                            </p>
                            </Link>
                        )
                    })}
               
                 </div>
            </div>
             </div>
             <SearchBar productsData={productsData}/>
             <div className={style.inspire}>
             <h2>11+ Original-High Quality Resources
             to inspire young minds!</h2>
             <Swiper
  // pagination={true}
  modules={[Pagination]}
  className="mySwiper"
  breakpoints={{
    // when the window width is >= 768px (tablet/desktop)
    768: {
      slidesPerView: 5,
    },
    // when the window width is < 768px (mobile)
    0: {
      slidesPerView: 1,
    },
  }}
>
  {filteredProducts.map((product) => (
    <SwiperSlide key={product.id}>
      <ProductCard product={product} />
    </SwiperSlide>
  ))}
</Swiper>
             </div>

             <div className={style.digitalBck}>
              <div className='container'>
                <div className={style.digitalDis}>
                  <div className={style.digitalContent}>
                      <h3>More Fun,
                      More Learning</h3>
                      <p>Dive into our Signature Digital Unit Studies!</p>
                      <Link to="/">Shop All</Link>
                  </div>
                  <div className={style.digitalSwiper}>
                  <Swiper
  // pagination={true}
  modules={[Pagination]}
  className="mySwiper"
  breakpoints={{
    // when the window width is >= 768px (tablet/desktop)
    768: {
      slidesPerView: 3,
    },
    // when the window width is < 768px (mobile)
    0: {
      slidesPerView: 1,
    },
  }}
>
{productsData
  .filter((product) => product.category === "Digital Unit Studies")
  .map((product) => (
    <SwiperSlide key={product.id}>
      <ProductCard product={product} />
    </SwiperSlide>
  ))}

</Swiper>
                  </div>
                </div>
              </div>
             </div>
             <div className={style.foundersBck}>
             <div className='container'>
              <Link to="/">Did you Know?</Link>
             <img src='/image/founder.png' />
             <p>and together, we have shipped over <span> 100,000 EDventures around the US & Canada</span></p>
             </div>
              </div>
            <KidsTestimonials removeTitle="remove"/>
            <Newsletter  increase="increase" changeColor="zooColor"/>
            </>
  );
};

export default AllShopV3;
