import React from "react";
import style from "./CopyStore.module.css"
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { Helmet } from "react-helmet";
const MainStore = () =>{
    const box = [
        {
            bigImage:"/image/one.png",
            bckyellow:"/image/ybck.png",
            changeBck:"changeCub",
            labelOne:"3-5 Year Olds",
            labelTwo:"Designed for Preschoolers",
            title:"Animal EDventures",
            description:"Each box Includes: 11+ Original high-quality resources to inspire young zoologists!",
            descriptionTwo:"Fun & Educational",
            linkOne:"/cub-plan",
            linkTwo:"/cub-club",
            icon:"/image/icon/cubb.svg",
            textIcon:"Cub Club",
              ridirect:"Shop One-time Boxes",
              ridirectUrl:"/zoo-store/one-time-boxes"
        },
        {
            bigImage:"/image/dig.png ",
            changeBck:"changeZoo",
            bckyellow:"/image/grbck.png",
            labelOne:"5-12 Year Olds",
            labelTwo:"",
            title:" Digital Animal Studies",
            description:"Includes: Interactive learning, hands-on projects, rich multimedia content and more!",
            descriptionTwo:"Fun-Filled",
            linkOne:"/zoologist-plan",
            linkTwo:"/zoologist-club",
            icon:"/image/icon/zooo.svg",
            textIcon:"Zoologist Club",
            ridirect:"Shop Digital Unit Studies",
              ridirectUrl:"/zoo-store/digital-studies"
        },
    ]
    return(
           <div className="container">
           <Helmet>
              <title> Kids’ Zoology Subscription Boxes | My ZOO Box</title>
              <meta name="description" content="Discover the wonder of wildlife with My ZOO Box – your monthly subscription box filled seriously fun, engaging, & educational zoology adventures designed by professional educators and real-life zoologists every month! " />
           </Helmet>
           <div className={style.bigBoxDis}>
            {box.map((item) =>{
                return(
                    <div 
                    className={`${style.bigBoxes} ${style[item.changeBck]}`} 
                    style={{ backgroundImage: `url(${item.bckyellow})` }}
                  >
                        
                  
                    <div className={`${style.bigBoxesContent }  ${style[item.changeBck]}`}>
                        <p>Discover Our</p>
                        <h3>{item.descriptionTwo}</h3>
                       <div className={style.shopDif}>
                       <h2>{item.title} </h2>
                        </div>
                       <p>{item.description}</p>
               
                       <div className={style.bigBoxButton}> 
                           <Link to={item.ridirectUrl}  onClick={() => animateScroll.scrollToTop()}>Shop Now</Link>
                       </div>
                    </div>
                    <img src={item.bigImage} className={style.mainImage}/>
               </div>
                )
            })}
           </div>
        </div>
    )
}
export default MainStore;